import { useUserStore } from "~/stores/user"
import { useDialog } from "~/composables/useDialog"
import { dialogConfirm } from "~/components/custom-dialog/custom"

/**
 * 处理未登录弹框
 */

export const useUnLogin = () => {
  const userStore = useUserStore()
  const { t } = useI18n()
  const handleUnLogin = async () => {
    if (!userStore.isLogin) {
      const confirm = await dialogConfirm(
        t("game.needLoginFirst"),
        "Reminder",
        {
          showCancelButton: true,
        }
      )
      if (confirm) useDialog().openDialog("PwdSign")

      return true
    }
    return false
  }
  return {
    handleUnLogin,
  }
}
